<template>
 <b-field :label="label">
   <b-select
    v-model="model[field.key]"
    v-on:input="emitToParent"
    placeholder="Select a country code">
     <option
        v-for="option in countryCodes"
        :value="option.countryCodeAlpha"
        :key="option.countryCodeAlpha">
       {{ option.name }}
     </option>
   </b-select>
 </b-field>
</template>
<script>
import countryCodes from '@/data/countryCodes.json';

export default {
  name: 'countryCodes',
  props: {
    title: String,
    value: String,
    form: Object,
    field: Object,
    model: Object,
    to: Object,
  },
  mounted() {
    this.countryCodes = countryCodes;
  },
  data() {
    return {
      countryCodes: [],
      countryCode: this.value,
      label: this.$props.field.templateOptions.wrapper.properties.label,
    };
  },
  methods: {
    emitToParent() {
      this.$emit('input', this.countryCode);
    },
  },
};
</script>
